import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const OccurrenceTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateText, setNewTemplateText] = useState('');
  const [editTemplate, setEditTemplate] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchTemplates = async () => {
      const templatesSnapshot = await getDocs(collection(firestore, 'templates'));
      setTemplates(templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchTemplates();
  }, [user, navigate]);

  const handleAddTemplate = async () => {
    if (newTemplateName.trim() !== '' && newTemplateText.trim() !== '') {
      await addDoc(collection(firestore, 'templates'), { name: newTemplateName, text: newTemplateText });
      setNewTemplateName('');
      setNewTemplateText('');
      const templatesSnapshot = await getDocs(collection(firestore, 'templates'));
      setTemplates(templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleDeleteTemplate = async (id) => {
    await deleteDoc(doc(firestore, 'templates', id));
    const templatesSnapshot = await getDocs(collection(firestore, 'templates'));
    setTemplates(templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleEditTemplate = (template) => {
    setEditTemplate(template);
    setNewTemplateName(template.name);
    setNewTemplateText(template.text);
  };

  const handleSaveTemplate = async () => {
    if (editTemplate && newTemplateName.trim() !== '' && newTemplateText.trim() !== '') {
      const templateRef = doc(firestore, 'templates', editTemplate.id);
      await updateDoc(templateRef, { name: newTemplateName, text: newTemplateText });
      setEditTemplate(null);
      setNewTemplateName('');
      setNewTemplateText('');
      const templatesSnapshot = await getDocs(collection(firestore, 'templates'));
      setTemplates(templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleSelectTemplate = (template) => {
    setEditTemplate(template);
    setNewTemplateName(template.name);
    setNewTemplateText(template.text);
  };

  return (
    <div className="container roboto-condensed">
      <h2>Modelos de Ocorrência</h2>
      <hr />
      <div className="form-group">
        <label>Nome do Modelo:</label>
        <input
          type="text"
          className="form-control"
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
        />
        <label>Texto do Modelo:</label>
        <textarea
          className="form-control"
          value={newTemplateText}
          onChange={(e) => setNewTemplateText(e.target.value)}
          rows="20"
        />
        {editTemplate ? (
          <button onClick={handleSaveTemplate} className="btn btn-success mt-2">Salvar Modelo</button>
        ) : (
          <button onClick={handleAddTemplate} className="btn btn-primary mt-2">Adicionar Modelo</button>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>Modelos Criados</h3>
          <ul className="list-group">
            {templates.map(template => (
              <li key={template.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div onClick={() => handleSelectTemplate(template)} style={{ cursor: 'pointer' }}>
                  <strong>{template.name}</strong>
                </div>
                <div>
                  <button onClick={() => handleEditTemplate(template)} className="btn btn-secondary btn-sm">Editar</button>
                  <button onClick={() => handleDeleteTemplate(template.id)} className="btn btn-danger btn-sm ml-2">Excluir</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OccurrenceTemplates;
