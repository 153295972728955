import React, { useState, useEffect } from 'react';
import { storage, firestore } from '../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import 'bootstrap-icons/font/bootstrap-icons.css';

const PhotoUpload = ({ criminalId }) => {
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const photosCollectionRef = collection(firestore, 'pessoas', criminalId, 'fotos');
      const photosSnapshot = await getDocs(photosCollectionRef);
      setPhotos(photosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchPhotos();
  }, [criminalId]);

  const handleUpload = async () => {
    if (!photo) {
      alert('Por favor, selecione uma foto.');
      return;
    }

    const user = getAuth().currentUser;
    if (!user) {
      alert('Você precisa estar autenticado para enviar uma foto.');
      return;
    }

    setLoading(true);
    try {
      const storageRef = ref(storage, `pessoas/${criminalId}/${photo.name}`);
      await uploadBytes(storageRef, photo);
      const photoUrl = await getDownloadURL(storageRef);

      const photosCollectionRef = collection(firestore, 'pessoas', criminalId, 'fotos');
      await addDoc(photosCollectionRef, {
        url: photoUrl,
        isMain: false,
        uploadedAt: new Date(),
      });

      alert('Foto enviada com sucesso!');
      setPhoto(null);
      const photosSnapshot = await getDocs(photosCollectionRef);
      setPhotos(photosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      alert('Erro ao enviar foto: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={(e) => setPhoto(e.target.files[0])} />
      <button onClick={handleUpload} disabled={loading}>
        {loading ? <i className="bi bi-hourglass-split"></i> : <i className="bi bi-database-fill-up"></i>}
      </button>
    </div>
  );
};

export default PhotoUpload;
