import React, { useState } from 'react';
import { auth } from '../firebase/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      setAlertType('danger');
      setAlertMessage('Todos os campos são obrigatórios!');
      return;
    }
    setLoading(true);
    try {
      console.log("Tentando fazer login com email:", email, "e senha:", password);
      await signInWithEmailAndPassword(auth, email, password);
      setAlertType('primary');
      setAlertMessage('Login feito com sucesso!');
      setLoading(false);
      setShowForm(false);
      setTimeout(() => {
        navigate('/criminosos');
      }, 2000);
    } catch (error) {
      console.error("Erro de login: ", error);
      setAlertType('danger');
      setAlertMessage(`Login recusado! Erro: ${error.message}`);
      setLoading(false);
    }
  };

  const containerStyles = {
    backgroundColor: '#1b1f22',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Roboto Condensed, sans-serif',
    maxWidth: '400px',
    margin: '100px auto 20px auto',
    color: '#fff',
    textAlign: 'center'
  };

  const formGroupStyles = {
    marginBottom: '15px'
  };

  const btnStyles = {
    backgroundColor: '#4c0bce',
    borderColor: '#4c0bce',
    color: '#fff',
    width: '100%'
  };

  const alertContainerStyles = {
    maxWidth: '400px',
    margin: '20px auto',
    textAlign: 'left'
  };

  return (
    <div>
      {alertMessage && (
        <div style={alertContainerStyles}>
          <div className={`alert alert-${alertType} d-flex align-items-center`} role="alert" style={{ whiteSpace: 'nowrap' }}>
            <i className={`bi ${alertType === 'primary' ? 'bi-shield-fill-check' : 'bi-shield-fill-exclamation'} flex-shrink-0 me-2`} role="img" aria-label="Alert icon"></i>
            <div>{alertMessage}</div>
          </div>
        </div>
      )}
      {showForm && (
        <div style={containerStyles}>
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group" style={formGroupStyles}>
              <label>Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group" style={formGroupStyles}>
              <label>Senha:</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary" style={btnStyles} disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span role="status">Loading...</span>
                </>
              ) : (
                <>
                  Entrar <i className="bi bi-power"></i>
                </>
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
