import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ManageTags = () => {
  const [faccoes, setFaccoes] = useState([]);
  const [hierarquias, setHierarquias] = useState([]);
  const [newFaccao, setNewFaccao] = useState('');
  const [newHierarquia, setNewHierarquia] = useState('');
  const [editFaccao, setEditFaccao] = useState(null);
  const [editHierarquia, setEditHierarquia] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchTags = async () => {
      const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
      const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
      setFaccoes(faccoesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setHierarquias(hierarquiasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchTags();
  }, [user, navigate]);

  const handleAddFaccao = async () => {
    if (newFaccao.trim() !== '') {
      await addDoc(collection(firestore, 'faccoes'), { nome: newFaccao });
      setNewFaccao('');
      const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
      setFaccoes(faccoesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleAddHierarquia = async () => {
    if (newHierarquia.trim() !== '') {
      await addDoc(collection(firestore, 'hierarquias'), { nome: newHierarquia });
      setNewHierarquia('');
      const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
      setHierarquias(hierarquiasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleDeleteFaccao = async (id) => {
    await deleteDoc(doc(firestore, 'faccoes', id));
    const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
    setFaccoes(faccoesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleDeleteHierarquia = async (id) => {
    await deleteDoc(doc(firestore, 'hierarquias', id));
    const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
    setHierarquias(hierarquiasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleEditFaccao = (id, nome) => {
    setEditFaccao({ id, nome });
  };

  const handleEditHierarquia = (id, nome) => {
    setEditHierarquia({ id, nome });
  };

  const handleSaveFaccao = async () => {
    if (editFaccao && editFaccao.nome.trim() !== '') {
      const faccaoRef = doc(firestore, 'faccoes', editFaccao.id);
      await updateDoc(faccaoRef, { nome: editFaccao.nome });
      setEditFaccao(null);
      const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
      setFaccoes(faccoesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleSaveHierarquia = async () => {
    if (editHierarquia && editHierarquia.nome.trim() !== '') {
      const hierarquiaRef = doc(firestore, 'hierarquias', editHierarquia.id);
      await updateDoc(hierarquiaRef, { nome: editHierarquia.nome });
      setEditHierarquia(null);
      const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
      setHierarquias(hierarquiasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  return (
    <div className="container roboto-condensed">
      <h2>Gerenciar Tags</h2>
      <hr />
      <div className="form-group">
        <label>Nova Facção:</label>
        <input
          type="text"
          className="form-control"
          value={newFaccao}
          onChange={(e) => setNewFaccao(e.target.value)}
        />
        <button onClick={handleAddFaccao} className="btn btn-primary mt-2">Adicionar Facção</button>
      </div>
      <div className="form-group">
        <label>Nova Hierarquia:</label>
        <input
          type="text"
          className="form-control"
          value={newHierarquia}
          onChange={(e) => setNewHierarquia(e.target.value)}
        />
        <button onClick={handleAddHierarquia} className="btn btn-primary mt-2">Adicionar Hierarquia</button>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h3>Facções</h3>
          <ul className="list-group">
            {faccoes.map(faccao => (
              <li key={faccao.id} className="list-group-item d-flex justify-content-between align-items-center">
                {editFaccao && editFaccao.id === faccao.id ? (
                  <input
                    type="text"
                    value={editFaccao.nome}
                    onChange={(e) => setEditFaccao({ ...editFaccao, nome: e.target.value })}
                    className="form-control"
                  />
                ) : (
                  faccao.nome
                )}
                <div>
                  {editFaccao && editFaccao.id === faccao.id ? (
                    <button onClick={handleSaveFaccao} className="btn btn-success btn-sm">Salvar</button>
                  ) : (
                    <button onClick={() => handleEditFaccao(faccao.id, faccao.nome)} className="btn btn-secondary btn-sm">Editar</button>
                  )}
                  <button onClick={() => handleDeleteFaccao(faccao.id)} className="btn btn-danger btn-sm ml-2">Excluir</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <h3>Hierarquias</h3>
          <ul className="list-group">
            {hierarquias.map(hierarquia => (
              <li key={hierarquia.id} className="list-group-item d-flex justify-content-between align-items-center">
                {editHierarquia && editHierarquia.id === hierarquia.id ? (
                  <input
                    type="text"
                    value={editHierarquia.nome}
                    onChange={(e) => setEditHierarquia({ ...editHierarquia, nome: e.target.value })}
                    className="form-control"
                  />
                ) : (
                  hierarquia.nome
                )}
                <div>
                  {editHierarquia && editHierarquia.id === hierarquia.id ? (
                    <button onClick={handleSaveHierarquia} className="btn btn-success btn-sm">Salvar</button>
                  ) : (
                    <button onClick={() => handleEditHierarquia(hierarquia.id, hierarquia.nome)} className="btn btn-secondary btn-sm">Editar</button>
                  )}
                  <button onClick={() => handleDeleteHierarquia(hierarquia.id)} className="btn btn-danger btn-sm ml-2">Excluir</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ManageTags;
