import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase/firebase';
import { doc, getDoc, collection, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import PhotoUpload from './PhotoUpload';
import '../styles.css';
import { useAuth } from '../hooks/useAuth'; // Importa o hook de autenticação

const CriminalDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [criminal, setCriminal] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [observations, setObservations] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingObservations, setIsEditingObservations] = useState(false);
  const [faccoes, setFaccoes] = useState([]);
  const [hierarquias, setHierarquias] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isPrisoner, setIsPrisoner] = useState(false);
  const [isDead, setIsDead] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const { user } = useAuth(); // Obtém o estado do usuário autenticado

  useEffect(() => {
    const fetchCriminal = async () => {
      if (!user) { // Verifica se o usuário está autenticado
        navigate('/login'); // Redireciona para a página de login se não estiver autenticado
        return;
      }

      const criminalRef = doc(firestore, 'pessoas', id);
      const docSnap = await getDoc(criminalRef);
      if (docSnap.exists()) {
        const criminalData = docSnap.data();
        setCriminal({ id: docSnap.id, ...criminalData });
        setIsPrisoner(criminalData.isPrisoner || false);
        setIsDead(criminalData.isDead || false);
        setIsRequested(criminalData.isRequested || false);
        setObservations(criminalData.observations || '');
      } else {
        console.error("No such document!");
      }
    };
    fetchCriminal();
  }, [id, navigate, user]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const photosCollectionRef = collection(firestore, 'pessoas', id, 'fotos');
      const photosSnapshot = await getDocs(photosCollectionRef);
      const photosList = photosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (criminal && criminal.fotoUrl && !photosList.some(photo => photo.url === criminal.fotoUrl)) {
        photosList.push({ id: 'main', url: criminal.fotoUrl, isMain: true });
      }

      setPhotos(photosList);
    };
    fetchPhotos();
  }, [id, criminal]);

  useEffect(() => {
    const fetchTags = async () => {
      const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
      const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
      setFaccoes(faccoesSnapshot.docs.map(doc => doc.data().nome));
      setHierarquias(hierarquiasSnapshot.docs.map(doc => doc.data().nome));
    };
    fetchTags();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const criminalRef = doc(firestore, 'pessoas', id);
      await updateDoc(criminalRef, {
        nome: criminal.nome,
        apelido: criminal.apelido,
        nomeMae: criminal.nomeMae,
        nomePai: criminal.nomePai,
        cpf: criminal.cpf,
        identidade: criminal.identidade,
        dataNascimento: criminal.dataNascimento,
        endereco: criminal.endereco,
        faccao: criminal.faccao,
        hierarquia: criminal.hierarquia,
        dataAtualizacao: new Date(),
        observations,
        isPrisoner,
        isDead,
        isRequested,
      });

      const historyRef = collection(firestore, 'pessoas', id, 'historico');
      await addDoc(historyRef, {
        usuario_id: "Admin",
        mudanca: "Atualização de dados da pessoa",
        data_mudanca: new Date()
      });

      alert('Dados atualizados com sucesso!');
      setIsEditing(false);
      setIsEditingObservations(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleTogglePrisoner = async (e) => {
    try {
      const criminalRef = doc(firestore, 'pessoas', id);
      await updateDoc(criminalRef, { isPrisoner: e.target.checked });
      setIsPrisoner(e.target.checked);
    } catch (error) {
      console.error("Erro ao atualizar o status de preso: ", error);
    }
  };

  const handleToggleDead = async (e) => {
    try {
      const criminalRef = doc(firestore, 'pessoas', id);
      await updateDoc(criminalRef, { isDead: e.target.checked });
      setIsDead(e.target.checked);
    } catch (error) {
      console.error("Erro ao atualizar o status de morto: ", error);
    }
  };

  const handleToggleRequested = async (e) => {
    try {
      const criminalRef = doc(firestore, 'pessoas', id);
      await updateDoc(criminalRef, { isRequested: e.target.checked });
      setIsRequested(e.target.checked);
    } catch (error) {
      console.error("Erro ao atualizar o status de pedido: ", error);
    }
  };

  const handleObservationsUpdate = async () => {
    try {
      const criminalRef = doc(firestore, 'pessoas', id);
      await updateDoc(criminalRef, { observations });
    } catch (error) {
      console.error("Erro ao atualizar observações: ", error);
    }
  };

  const handleSetMainPhoto = async (photoId) => {
    try {
      const photosCollectionRef = collection(firestore, 'pessoas', id, 'fotos');
      const photosSnapshot = await getDocs(photosCollectionRef);

      photosSnapshot.forEach(async (photoDoc) => {
        const photoRef = doc(firestore, 'pessoas', id, 'fotos', photoDoc.id);
        await updateDoc(photoRef, { isMain: photoDoc.id === photoId });
      });

      const updatedPhotos = photosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPhotos(updatedPhotos);
      
      if (photoId !== 'main') {
        const photoDoc = await getDoc(doc(firestore, 'pessoas', id, 'fotos', photoId));
        await updateDoc(doc(firestore, 'pessoas', id), {
          fotoUrl: photoDoc.data().url,
        });
        setCriminal({ ...criminal, fotoUrl: photoDoc.data().url });
      }

    } catch (error) {
      console.error("Erro ao definir foto principal: ", error);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const photoRef = doc(firestore, 'pessoas', id, 'fotos', photoId);
      await deleteDoc(photoRef);
      setPhotos(photos.filter(photo => photo.id !== photoId));
    } catch (error) {
      console.error("Erro ao excluir foto: ", error);
    }
  };

  const handleDeleteProfile = async () => {
    if (window.confirm("Tem certeza que deseja excluir este perfil?")) {
      try {
        const criminalRef = doc(firestore, 'pessoas', id);
        await deleteDoc(criminalRef);
        alert('Perfil excluído com sucesso!');
        navigate('/criminosos');
      } catch (error) {
        console.error("Erro ao excluir o perfil: ", error);
      }
    }
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const toggleEditingObservations = () => {
    setIsEditingObservations(!isEditingObservations);
  };

  const openPhotoViewer = (src) => {
    setSelectedPhoto(src);
  };

  const closePhotoViewer = () => {
    setSelectedPhoto(null);
  };

  if (!criminal) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container-outer">
      <div className="header">
        <h2>Dados do Perfil</h2>
        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
          <Link to={`/criminosos/${id}/historico`} className="btn btn-warning">
            <i className="bi bi-database-exclamation"></i>
          </Link>
          {isEditing ? (
            <button onClick={handleUpdate} className="btn btn-success">
              <i className="bi bi-floppy"></i>
            </button>
          ) : (
            <button onClick={toggleEditing} className="btn btn-secondary">
              <i className="bi bi-pencil"></i>
            </button>
          )}
          <button onClick={handleDeleteProfile} className="btn btn-danger">
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <hr></hr>
      <div className="container-inner">
        <div className="content-box">
          <img
            src={criminal.fotoUrl}
            alt="Foto da pessoa"
            className="photo-main"
            onClick={() => openPhotoViewer(criminal.fotoUrl)}
          />
        </div>
        <div className="content-box">
          <ul className="list-group">
            {isEditing ? (
              <>
                <li className="list-group-item">
                  <strong>Nome:</strong>
                  <input
                    type="text"
                    value={criminal.nome}
                    onChange={(e) => setCriminal({ ...criminal, nome: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Apelido:</strong>
                  <input
                    type="text"
                    value={criminal.apelido}
                    onChange={(e) => setCriminal({ ...criminal, apelido: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Nome da Mãe:</strong>
                  <input
                    type="text"
                    value={criminal.nomeMae}
                    onChange={(e) => setCriminal({ ...criminal, nomeMae: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Nome do Pai:</strong>
                  <input
                    type="text"
                    value={criminal.nomePai}
                    onChange={(e) => setCriminal({ ...criminal, nomePai: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>CPF:</strong>
                  <input
                    type="text"
                    value={criminal.cpf}
                    onChange={(e) => setCriminal({ ...criminal, cpf: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Identidade:</strong>
                  <input
                    type="text"
                    value={criminal.identidade}
                    onChange={(e) => setCriminal({ ...criminal, identidade: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Data de Nascimento:</strong>
                  <input
                    type="date"
                    value={criminal.dataNascimento}
                    onChange={(e) => setCriminal({ ...criminal, dataNascimento: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Endereço:</strong>
                  <input
                    type="text"
                    value={criminal.endereco}
                    onChange={(e) => setCriminal({ ...criminal, endereco: e.target.value })}
                    className="form-control"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Facção:</strong>
                  <select
                    className="form-control"
                    value={criminal.faccao}
                    onChange={(e) => setCriminal({ ...criminal, faccao: e.target.value })}
                  >
                    <option value="">Selecione a Facção</option>
                    {faccoes.map((faccao, index) => (
                      <option key={index} value={faccao}>{faccao}</option>
                    ))}
                  </select>
                </li>
                <li className="list-group-item">
                  <strong>Hierarquia:</strong>
                  <select
                    className="form-control"
                    value={criminal.hierarquia}
                    onChange={(e) => setCriminal({ ...criminal, hierarquia: e.target.value })}
                  >
                    <option value="">Selecione a Hierarquia</option>
                    {hierarquias.map((hierarquia, index) => (
                      <option key={index} value={hierarquia}>{hierarquia}</option>
                    ))}
                  </select>
                </li>
                <li className="list-group-item">
                  <strong>Preso: </strong>
                  <input
                    type="checkbox"
                    checked={isPrisoner}
                    onChange={handleTogglePrisoner}
                    className="form-check-input"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Morto: </strong>
                  <input
                    type="checkbox"
                    checked={isDead}
                    onChange={handleToggleDead}
                    className="form-check-input"
                  />
                </li>
                <li className="list-group-item">
                  <strong>Pedido: </strong>
                  <input
                    type="checkbox"
                    checked={isRequested}
                    onChange={handleToggleRequested}
                    className="form-check-input"
                  />
                </li>
              </>
            ) : (
              <>
                <li className="list-group-item"><strong>Nome:</strong> {criminal.nome}</li>
                <li className="list-group-item"><strong>Apelido:</strong> {criminal.apelido}</li>
                <li className="list-group-item"><strong>Nome da Mãe:</strong> {criminal.nomeMae}</li>
                <li className="list-group-item"><strong>Nome do Pai:</strong> {criminal.nomePai}</li>
                <li className="list-group-item"><strong>CPF:</strong> {criminal.cpf}</li>
                <li className="list-group-item"><strong>Identidade:</strong> {criminal.identidade}</li>
                <li className="list-group-item"><strong>Data de Nascimento:</strong> {criminal.dataNascimento}</li>
                <li className="list-group-item"><strong>Endereço:</strong> {criminal.endereco}</li>
                <li className="list-group-item"><strong>Facção:</strong> {criminal.faccao}</li>
                <li className="list-group-item"><strong>Hierarquia:</strong> {criminal.hierarquia}</li>
                <li className="list-group-item"><strong>Preso:</strong> {isPrisoner ? "Sim" : "Não"}</li>
                <li className="list-group-item"><strong>Morto:</strong> {isDead ? "Sim" : "Não"}</li>
                <li className="list-group-item"><strong>Pedido:</strong> {isRequested ? "Sim" : "Não"}</li>
              </>
            )}
          </ul>
        </div>
        <div className="content-box">
          <h3>Fotos Adicionais</h3>
          <div className="photo-gallery">
            {photos.map(photo => (
              <div key={photo.id} className="photo-item">
                <img
                  src={photo.url}
                  alt="Foto adicional"
                  className="photo-additional"
                  onClick={() => openPhotoViewer(photo.url)}
                />
                {!photo.isMain && (
                  <>
                    <button onClick={() => handleSetMainPhoto(photo.id)} className="btn-set-main">
                      <i className="bi bi-file-person-fill"></i>
                    </button>
                    <button onClick={() => handleDeletePhoto(photo.id)} className="btn-delete-photo">
                      <i className="bi bi-trash"></i>
                    </button>
                  </>
                )}
                {photo.isMain && (
                  <span className="main-photo-indicator">Principal</span>
                )}
              </div>
            ))}
          </div>
          <PhotoUpload criminalId={id} />
        </div>
      </div>
      <div className="container-inner">
        <div className="content-box">
          <h3>Observações</h3>
          {isEditingObservations ? (
            <>
              <textarea
                className="form-control"
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
                rows="10"
              />
              <button onClick={() => { setIsEditingObservations(false); handleObservationsUpdate(); }} className="btn btn-primary mt-2">
                <i className="bi bi-floppy"></i> Salvar
              </button>
            </>
          ) : (
            <>
              <p>{observations}</p>
              <button onClick={toggleEditingObservations} className="btn btn-primary mt-2">
                <i className="bi bi-pencil"></i> Editar
              </button>
            </>
          )}
        </div>
        <div className="content-box">
          <h3>Endereço</h3>
          <p>{criminal.endereco}</p>
        </div>
        <div className="content-box">
          <h3>Situação</h3>
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Preso:----</strong>
              <input
                type="checkbox"
                checked={isPrisoner}
                onChange={handleTogglePrisoner}
                className="form-check-input"
              />
            </li>
            <li className="list-group-item">
              <strong>Morto:----</strong>
              <input
                type="checkbox"
                checked={isDead}
                onChange={handleToggleDead}
                className="form-check-input"
              />
            </li>
            <li className="list-group-item">
              <strong>Pedido:----</strong>
              <input
                type="checkbox"
                checked={isRequested}
                onChange={handleToggleRequested}
                className="form-check-input"
              />
            </li>
          </ul>
        </div>
      </div>
      {selectedPhoto && (
        <div className="photo-viewer" onClick={closePhotoViewer}>
          <img src={selectedPhoto} alt="Visualização da foto" />
        </div>
      )}
    </div>
  );
};

export default CriminalDetail;
