import { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { signOut } from 'firebase/auth';

const useIdleTimer = (timeout) => {
  const navigate = useNavigate();
  const timer = useRef(null);

  const logout = useCallback(async () => {
    await signOut(auth);
    navigate('/');
  }, [navigate]);

  const resetTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(logout, timeout);
  }, [logout, timeout]);

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    const handleEvent = () => {
      resetTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleEvent);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleEvent);
      });
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [resetTimer]);

  return null;
};

export default useIdleTimer;
