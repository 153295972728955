import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase/firebase';
import { collection, getDocs, doc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import '../styles.css'; // Certifique-se de que este caminho está correto

const History = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const historyCollectionRef = collection(doc(firestore, 'criminosos', id), 'historico');
        const querySnapshot = await getDocs(historyCollectionRef);
        setHistory(querySnapshot.docs.map(doc => doc.data()));
      } catch (err) {
        setError(err.message);
      }
    };

    fetchHistory();
  }, [id, user, navigate]);

  const containerStyles = {
    backgroundColor: '#1b1f22',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Roboto Condensed, sans-serif',
    color: '#fff'
  };

  return (
    <div className="container" style={containerStyles}>
      <h2>Histórico de Alterações</h2>
      {error && <p className="error-message">Erro: {error}</p>}
      <ul className="list-group">
        {history.map((item, index) => (
          <li key={index} className="list-group-item">
            <strong>Data:</strong> {new Date(item.data_mudanca.seconds * 1000).toLocaleString()} <br />
            <strong>Usuário:</strong> {item.usuario_id} <br />
            <strong>Mudança:</strong> {item.mudanca}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default History;
