import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { signOut } from 'firebase/auth';
import { Navbar, Nav } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';

const NavigationBar = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState(15 * 60);

  const handleLogout = useCallback(async () => {
    await signOut(auth);
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          handleLogout();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [handleLogout]);

  useEffect(() => {
    const resetTimer = () => setTimeRemaining(15 * 60);
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  if (loading) {
    return (
      <div className="container text-center roboto-condensed">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <Navbar expand="lg" className="mb-4 navbar roboto-condensed">
      <Navbar.Brand as={Link} to="/criminosos" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
        Qualifica-e
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/criminosos" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <i className="bi bi-journal-richtext"></i> Listar Qualificados
          </Nav.Link>
          <Nav.Link as={Link} to="/criminosos/cadastrar" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <i className="bi bi-person-add"></i> Cadastrar Pessoa
          </Nav.Link>
          <Nav.Link as={Link} to="/gerenciar-tags" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <i className="bi bi-tags"></i> Gerenciar Tags
          </Nav.Link>
          <Nav.Link as={Link} to="/modelos-ocorrencia" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <i className="bi bi-file-earmark-text"></i> Modelos de Ocorrência
          </Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link onClick={handleLogout} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            <i className="bi bi-box-arrow-left"></i> Logout
          </Nav.Link>
          <Nav.Link style={{ paddingLeft: '5px', paddingRight: '5px' }}>
            {formatTime(timeRemaining)}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
