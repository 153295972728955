import React, { useState, useEffect } from 'react';
import { firestore, storage } from '../firebase/firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const CriminalForm = () => {
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [cpf, setCpf] = useState('');
  const [identidade, setIdentidade] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [endereco, setEndereco] = useState('');
  const [foto, setFoto] = useState(null);
  const [faccao, setFaccao] = useState('');
  const [hierarquia, setHierarquia] = useState('');
  const [loading, setLoading] = useState(false);
  const [faccoes, setFaccoes] = useState([]);
  const [hierarquias, setHierarquias] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchTags = async () => {
      const faccoesSnapshot = await getDocs(collection(firestore, 'faccoes'));
      const hierarquiasSnapshot = await getDocs(collection(firestore, 'hierarquias'));
      setFaccoes(faccoesSnapshot.docs.map(doc => doc.data().nome));
      setHierarquias(hierarquiasSnapshot.docs.map(doc => doc.data().nome));
    };
    fetchTags();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('Você precisa estar autenticado para cadastrar uma pessoa.');
      setLoading(false);
      return;
    }

    if (!nome || !nomeMae || !dataNascimento) {
      alert('Por favor, preencha todos os campos obrigatórios (Nome, Nome da Mãe e Data de Nascimento).');
      setLoading(false);
      return;
    }

    try {
      let fotoUrl = '';
      if (foto) {
        const storageRef = ref(storage, `pessoas/${cpf}/${foto.name}`);
        await uploadBytes(storageRef, foto);
        fotoUrl = await getDownloadURL(storageRef);
      }

      const formattedData = {
        nome: nome.trim().toUpperCase(),
        apelido: apelido.trim().toUpperCase(),
        nomeMae: nomeMae.trim().toUpperCase(),
        nomePai: nomePai.trim().toUpperCase(),
        cpf: cpf.trim(),
        identidade: identidade.trim().toUpperCase(),
        dataNascimento: dataNascimento.trim(),
        endereco: endereco.trim().toUpperCase(),
        faccao: faccao.trim().toUpperCase(),
        hierarquia: hierarquia.trim().toUpperCase(),
        fotoUrl,
        dataCriacao: new Date(),
        dataAtualizacao: new Date()
      };

      const pessoaRef = await addDoc(collection(firestore, 'pessoas'), formattedData);

      await addDoc(collection(firestore, 'pessoas', pessoaRef.id, 'historico'), {
        usuario_id: user.uid,
        mudanca: "Cadastro de nova pessoa",
        data_mudanca: new Date()
      });

      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);

      setNome('');
      setApelido('');
      setNomeMae('');
      setNomePai('');
      setCpf('');
      setIdentidade('');
      setDataNascimento('');
      setEndereco('');
      setFoto(null);
      setFaccao('');
      setHierarquia('');
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="container-outer">
      <div className="container-inner">
        <div className="content-box">
          <h2><i className="bi bi-person-add"></i> Cadastrar Pessoa</h2>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            {showAlert && <Alert variant="success">Pessoa cadastrada com sucesso!</Alert>}
            <div className="form-group">
              <label>Nome:</label>
              <input
                type="text"
                className="form-control"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Apelido:</label>
              <input
                type="text"
                className="form-control"
                value={apelido}
                onChange={(e) => setApelido(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nome da Mãe:</label>
              <input
                type="text"
                className="form-control"
                value={nomeMae}
                onChange={(e) => setNomeMae(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Nome do Pai:</label>
              <input
                type="text"
                className="form-control"
                value={nomePai}
                onChange={(e) => setNomePai(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>CPF:</label>
              <input
                type="text"
                className="form-control"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Identidade:</label>
              <input
                type="text"
                className="form-control"
                value={identidade}
                onChange={(e) => setIdentidade(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Data de Nascimento:</label>
              <input
                type="date"
                className="form-control"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Endereço:</label>
              <input
                type="text"
                className="form-control"
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Facção:</label>
              <select
                className="form-control"
                value={faccao}
                onChange={(e) => setFaccao(e.target.value)}
              >
                <option value="">Selecione a Facção</option>
                {faccoes.map((faccao, index) => (
                  <option key={index} value={faccao}>{faccao}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Hierarquia:</label>
              <select
                className="form-control"
                value={hierarquia}
                onChange={(e) => setHierarquia(e.target.value)}
              >
                <option value="">Selecione a Hierarquia</option>
                {hierarquias.map((hierarquia, index) => (
                  <option key={index} value={hierarquia}>{hierarquia}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Foto:</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFoto(e.target.files[0])}
              />
            </div>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="bi bi-database-fill-up"></i>} Cadastrar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CriminalForm;
