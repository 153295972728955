import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../firebase/firebase';
import { collection, getDocs, query, limit, startAfter } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import Fuse from 'fuse.js';

const placeholderImage = "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png";

const CriminalList = () => {
  const { user } = useAuth();
  const [criminosos, setCriminosos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState(null);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const criminalsPerPage = 48;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setLoading(false);
        return;
      }
      
      setLoading(true);
      try {
        const colRef = collection(firestore, 'pessoas');
        let q = query(colRef, limit(criminalsPerPage));
        if (lastVisible) {
          q = query(colRef, startAfter(lastVisible), limit(criminalsPerPage));
        }

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setIsEndOfList(true);
        } else {
          const newCriminals = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

          // Remover duplicatas
          setCriminosos((prev) => {
            const existingIds = new Set(prev.map(criminoso => criminoso.id));
            const filteredNewCriminals = newCriminals.filter(criminoso => !existingIds.has(criminoso.id));
            return [...prev, ...filteredNewCriminals];
          });

          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
      } catch (err) {
        setFetchError('Missing or insufficient permissions.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, lastVisible]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const fuse = new Fuse(criminosos, {
    keys: ['nome', 'faccao', 'hierarquia', 'apelido'],
    includeScore: true,
    threshold: 0.3
  });

  const results = searchTerm ? fuse.search(searchTerm) : criminosos.map(criminoso => ({ item: criminoso }));
  const filteredCriminosos = results.map(result => result.item);

  filteredCriminosos.sort((a, b) => a.nome.localeCompare(b.nome));

  const indexOfLastCriminal = currentPage * criminalsPerPage;
  const indexOfFirstCriminal = indexOfLastCriminal - criminalsPerPage;
  const currentCriminals = filteredCriminosos.slice(indexOfFirstCriminal, indexOfLastCriminal);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatNickname = (nickname) => {
    if (!nickname || nickname.toUpperCase() === "NÃO INFORMADO") return '';
    return nickname.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  };

  if (loading) {
    return (
      <div className="container text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (fetchError) {
    return (
      <div className="container text-center">
        <div className="alert alert-danger" role="alert">
          {fetchError}
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container text-center">
        <div className="alert alert-warning" role="alert">
          Você precisa estar autenticado para ver esta página.
        </div>
      </div>
    );
  }

  return (
    <div className="container-outer">
      <h2>
        <i className="bi bi-fingerprint"></i> Lista de Qualificados
        <hr></hr>
      </h2>
      <input 
        type="text" 
        placeholder="Buscar..." 
        value={searchTerm} 
        onChange={handleSearchChange}
        className="form-control"
        style={{ marginBottom: '20px' }}
      />
      <div className="profile-grid">
        {currentCriminals.map(criminoso => {
          const age = calculateAge(criminoso.dataNascimento);
          const formattedNickname = formatNickname(criminoso.apelido);
          const fullNameWithNickname = formattedNickname ? `${criminoso.nome} (${formattedNickname})` : criminoso.nome;
          return (
            <div key={criminoso.id} className="card">
              <div className="photo-container">
                <img 
                  src={criminoso.fotoUrl || placeholderImage} 
                  className="card-img-top" 
                  alt={fullNameWithNickname} 
                  onClick={() => navigate(`/criminosos/${criminoso.id}`)}
                />
                <div className={`age-indicator ${age >= 18 ? 'age-18' : 'age-17'}`}>
                  {age >= 18 ? '+18' : '-18'}
                </div>
                <div className="status-indicator">
                  {criminoso.isRequested && <span className="badge bg-warning">Pedido</span>}
                  {criminoso.isDead && <span className="badge bg-danger">Morto</span>}
                  {criminoso.isPrisoner && <span className="badge bg-primary">Preso</span>}
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">{fullNameWithNickname}</h5>
                {criminoso.tags && <p className="card-text">{criminoso.tags.join(', ')}</p>}
                <p className="card-text">Facção: {criminoso.faccao}</p>
                <p className="card-text">Hierarquia: {criminoso.hierarquia}</p>
              </div>
            </div>
          );
        })}
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
          </li>
          {Array.from({ length: Math.ceil(filteredCriminosos.length / criminalsPerPage) }, (_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
            </li>
          ))}
          <li className={`page-item ${isEndOfList ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CriminalList;
