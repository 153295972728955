import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import '../styles.css'; // Certifique-se de que este caminho está correto

const firebaseConfig = {
  apiKey: "AIzaSyB1Ont3WEDzWrAj8YAlX_IEUTiRSl4YAaE",
  authDomain: "qualifica-e.firebaseapp.com",
  projectId: "qualifica-e",
  storageBucket: "qualifica-e.appspot.com",
  messagingSenderId: "108263019797",
  appId: "1:108263019797:web:68fc7f43da58631b5dc51c",
  measurementId: "G-FN5SRRCBNM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
