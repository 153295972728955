import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Login from './components/Login';
import CriminalForm from './components/CriminalForm';
import CriminalList from './components/CriminalList';
import CriminalDetail from './components/CriminalDetail';
import PhotoUpload from './components/PhotoUpload';
import History from './components/History';
import ProtectedRoute from './components/ProtectedRoute';
import ManageTags from './components/ManageTags';
import OccurrenceTemplates from './components/OccurrenceTemplates';
import { AuthProvider } from './hooks/useAuth';
import IdleTimerWrapper from './IdleTimerWrapper';
import './styles.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <IdleTimerWrapper>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/criminosos/cadastrar" 
              element={
                <ProtectedRoute>
                  <CriminalForm />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/criminosos" 
              element={
                <ProtectedRoute>
                  <CriminalList />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/criminosos/:id" 
              element={
                <ProtectedRoute>
                  <CriminalDetail />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/criminosos/:id/fotos" 
              element={
                <ProtectedRoute>
                  <PhotoUpload />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/criminosos/:id/historico" 
              element={
                <ProtectedRoute>
                  <History />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/gerenciar-tags" 
              element={
                <ProtectedRoute>
                  <ManageTags />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/modelos-ocorrencia" 
              element={
                <ProtectedRoute>
                  <OccurrenceTemplates />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </IdleTimerWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;
